<template>
  <div class="container">
    <mall-cate @change-category="changeCategory" :categoryid="categoryid"></mall-cate>
    <div style="background:#f2f2f2; padding:2rem">
      <van-skeleton v-if="loading" title :row="8" />
      <product-list v-else :categoryid="categoryid"></product-list>
    </div>
  </div>
</template>
<script>
export default {
  name: "Mall",
  components: {
    MallCate: () => import("@/components/MallCategory.vue"),
    ProductList: () => import("@/components/ProductList.vue")
  },
  data() {
    return {
      active: parseInt(this.$route.query.active),
      loading:false,
      categoryid: this.$route.query.categoryid
    };
  },
  created() {
    document.title = "非遗商城";
    if (!this.checkMobile()) {
      window.location.href = "https://www.zgfeiyi.net/Page/Mall?id="+this.id;
    }
  },
  methods: {
    changeCategory: function(cateid) {
      this.categoryid = cateid;
    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMobile= /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      return isMobile;
    }
  }
};
</script>
<style scoped>
.container {
  padding: 1rem 0;
  background: #fff;
}
</style>