import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '../src/assets/js/api'
import axios from 'axios'
import wx from 'weixin-js-sdk'
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$pageTitle = "非遗老字号"
Vue.prototype.wx=wx
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
function setRem() {
  const baseSize = 32; // 设计稿宽度的1/100
  const baseWidth = 1920; // 设计稿的宽度，例如1920px
  const clientWidth = document.documentElement.clientWidth || window.innerWidth;
  const rem = (clientWidth / baseWidth) * baseSize;
  document.documentElement.style.fontSize = `${rem}px`;
}
Vue.directive('title', {
  inserted: function (el) {
    document.title = el.dataset.title
  }
})
window.addEventListener('resize', setRem);
document.addEventListener('DOMContentLoaded', setRem);