<template>
  <div class="home">
    <head-nav :active="active" @change-title="changeTitle"></head-nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    HeadNav: () => import("@/components/HeadNav.vue")
  },
  data() {
    return {
      active: parseInt(this.$route.query.active),
      shareInfo: null
    };
  },
  created() {
    if (!this.checkMobile()) {
      window.location.href = "https://www.zgfeiyi.net";
    } else {
      var that = this;
      that.$api.getShareInfo().then(res => {
        that.shareInfo = res.data;
        that.initWechatJsSDK();
      });
    }
  },
  methods: {
    initWechatJsSDK() {
      // 设置微信Js-SDK的配置信息
      this.wx.config({
        debug: false,
        appId: this.shareInfo.appId,
        timestamp: this.shareInfo.timestamp,
        nonceStr: this.shareInfo.nonceStr,
        signature: this.shareInfo.signature,
        jsApiList: this.shareInfo.jsApiList
      });

      // 配置完成后调用微信Js-SDK的ready函数
      this.wx.ready(() => {
        this.wx.onMenuShareAppMessage({
          title: this.shareInfo.title, // 分享标题
          desc: this.shareInfo.desc, // 分享描述
          link: this.shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.shareInfo.imgUrl, // 分享图标
          success: function() {
            // 设置成功
          }
        });
        this.wx.onMenuShareTimeline({
          title: this.shareInfo.title, // 分享标题
          link: this.shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.shareInfo.imgUrl, // 分享图标
          success: function() {
            // 设置成功
          }
        });
      });

      // 在配置出错的情况下调用错误处理函数
      this.wx.error(err => {
        console.error(err);
      });
    },
    changeTitle(title) {
      document.title = title;
    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
      return isMobile;
    }
  }
};
</script>
<style scoped>
.container {
  padding: 1.5rem;
  background: #f2f2f2;
}
</style>