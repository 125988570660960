<template>
  <div class="container">
    <van-empty v-if="nodata" description="暂无数据" />
    <van-skeleton v-if="loading" title :row="8" />
    <new-list v-if="!nodata&&!loading" :id="$route.query.channelid"></new-list>
  </div>
</template>
<script>
export default {
  name: "List",
  components: {
    NewList: () => import("@/components/ChannelNew.vue")
  },
  data() {
    return {
      nodata: false,
      loading: true,
      active: parseInt(this.$route.query.active),
      id: this.$route.query.channelid
    };
  },
  created() {
    if (!this.checkMobile()) {
      window.location.href = "https://www.zgfeiyi.net/Page/List?id="+this.id;
    }
    this.getChannelInfo();
  },
  methods: {
    getChannelInfo() {
      var that = this;
      var params = {
        channelid: that.id
      };
      that.$api.getChannelInfo(params).then(res => {
        that.loading = false;
        that.nodata = res.nodata;
        if (!res.nodata) {
          document.title = res.title;
        } else {
          document.title = "非遗老字号";
        }
      });
    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMobile= /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      return isMobile;
    }
  }
};
</script>
<style scoped>
.container{ background: #f2f2f2;}
</style>