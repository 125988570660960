import axios from 'axios';
 
// 创建axios实例
const service = axios.create({
  baseURL: 'https://www.zgfeiyi.net/', // API的base_url
  timeout: 50000 // 请求超时时间
});
service.interceptors.request.use(
    config => {
      // 可以在这里添加请求头等信息
      return config;
    },
    error => {
      // 请求错误处理
      console.log(error);
      return Promise.reject(error);
    }
  );
   
  // 响应拦截器
  service.interceptors.response.use(
    response => {
      const res = response.data;
      // 根据后端返回的数据进行处理，例如返回状态码等
      return res;
    },
    error => {
      // 响应错误处理
      console.log('err' + error); // for debug
      return Promise.reject(error);
    }
  );
   
  export default service;