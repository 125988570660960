import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NewList from '../views/List.vue'
import Mall from '../views/Mall.vue'
import Detail from '../views/Detail.vue'
import Product from '../views/Product.vue'
import Index from '../views/Index.vue'
import "vant/lib/index.css";
import { Tab, Tabs, Swipe, SwipeItem, Image as VanImage, List as VanList, Cell, CellGroup, Grid, GridItem, Col, Row, Tag, PullRefresh, Popup, Empty, Skeleton, Divider } from 'vant';
// Vue.use([Cell,CellGroup,List,VanImage,Swipe,SwipeItem,Tab,Tabs,VueRouter,Grid,GridItem,Col,Row]);
Vue.use(CellGroup);
Vue.use(VanList);
Vue.use(VanImage);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VueRouter)
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Cell);
Vue.use(Tag);
Vue.use(PullRefresh);
Vue.use(Popup);
Vue.use(Empty);
Vue.use(Skeleton);
Vue.use(Divider);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/Index',
    meta: {
      title: '非遗老字号'
    },
    children: [
      {
        path: '/Index',
        name: 'index',
        component: Index,
        meta: {
          isBack: false,
          title: '非遗老字号',
          isNeedLogin: true
        }
      },
      {
        path: '/List',
        name: 'List',
        component: NewList,
        meta: {
          title: '非遗老字号'
        }
      }, {
        path: '/Mall',
        name: 'Mall',
        component: Mall,
        meta: {
          title: '非遗商城 - 非遗老字号'
        }
      }]
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: Detail,
    meta: {
      title: '非遗老字号'
    }
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product,
    meta: {
      title: '非遗老字号'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
