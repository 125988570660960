<template>
  <div class="container">
    <hot-new></hot-new>
    <mall-cate></mall-cate>
    <focus-new></focus-new>
    <new-list></new-list>
  </div>
</template>
  <script>
export default {
  name: "MainIndex",
  components: {
    FocusNew: () => import("@/components/FocusNew.vue"),
    HotNew: () => import("@/components/HotNew.vue"),
    MallCate: () => import("@/components/MallCate.vue"),
    NewList: () => import("@/components/NewList.vue")
  }
};
</script>