<template>
  <div class="home">
    <head-nav></head-nav>
    <van-empty v-if="nodata" description="暂无数据" />
    <van-skeleton v-if="loading" title :row="4" />
    <div class="container" v-if="!nodata&&!loading">
      <h4 class="title">{{title }}</h4>
      <p class="posttime">{{ posttime }}</p>
      <van-divider
        :style="{ color: '#dddddd', borderColor: '#dddddd', height:'1rem'}"
        :hairline="false"
      />
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
    
<script scoped>
export default {
  name: "Home",
  components: {
    HeadNav: () => import("@/components/DetailHead.vue") //异步组件加载方式
  },
  data() {
    return {
      nodata: false,
      title: "",
      posttime: "",
      loading: true,
      content: "",
      id: this.$route.query.id,
      shareInfo:null
    };
  },
  created() {
    if (!this.checkMobile()) {
      window.location.href = "https://www.zgfeiyi.net/Page/Product/?id=" + this.id;
    } else {
      this.loadData();
    }
  },
  methods: {
    loadData() {
      var that = this;
      var params = { id: that.id };
      that.$api.getProductData(params).then(res => {
        that.loading = false;
        that.nodata = res.nodata;
        that.title = decodeURIComponent(res.title.replace(/\+/g, "%20"));
        that.posttime = res.posttime;
        that.content = decodeURIComponent(res.content.replace(/\+/g, "%20"));
        document.title = decodeURIComponent(res.title.replace(/\+/g, "%20"));
      });
    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var isMobile= /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      return isMobile;
    },
    initWechatJsSDK() {
      // 设置微信Js-SDK的配置信息
      this.wx.config({
        debug: false,
        appId: this.shareInfo.appId,
        timestamp: this.shareInfo.timestamp,
        nonceStr: this.shareInfo.nonceStr,
        signature: this.shareInfo.signature,
        jsApiList: this.shareInfo.jsApiList
      });

      // 配置完成后调用微信Js-SDK的ready函数
      this.wx.ready(() => {
        this.wx.onMenuShareAppMessage({
          title: decodeURIComponent(this.shareInfo.title.replace(/\+/g, "%20")), // 分享标题
          desc: decodeURIComponent(this.shareInfo.desc.replace(/\+/g, "%20")), // 分享描述
          link: this.shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.shareInfo.imgUrl, // 分享图标
          success: function() {
            // 设置成功
          }
        });
        this.wx.onMenuShareTimeline({
          title: decodeURIComponent(this.shareInfo.title.replace(/\+/g, "%20")), // 分享标题
          link: this.shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.shareInfo.imgUrl, // 分享图标
          success: function() {
            // 设置成功
          }
        });
      });

      // 在配置出错的情况下调用错误处理函数
      this.wx.error(err => {
        console.error(err);
      });
    }
  }
};
</script>
<style>
.container {
  margin-top: 5px;
  padding: 1.5rem;
  background: #fff;
}
.title {
  font-size: 3rem;
  text-align: left;
  margin: 1rem 0;
  color: #333;
}
.posttime {
  font-size: 2.2rem;
  color: #333;
  text-align: left;
  margin-top: 1rem;
}
.content p {
  margin: 0;
  padding: 0;
  line-height: 4rem;
  font-size: 2.4rem;
  color: #333333;
  text-align: left;
}
.content img {
  max-width: 100%;
}
.content strong {
  font-size: 2.4rem;
}
</style>